import React from "react";
import axios from "axios";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
  Card
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { EjNavbar } from "../EjNavbar";
import "../../styles/balances.scss";
import { throwStatement } from "@babel/types";

class Balances extends React.Component {
  state = {
    amazonBalance: null,
    nonAmazonBalance: null,
    isLoading: true,
    isLoadingNonAmazon: true,
    isLoadingFlipKart: true,
    errors: null,
    totalAllResellersCardBalance: null,
    isLoadingResellersCardBalance: true,
    flipkartBalance: null
  };

  getAmazonBalance() {
    axios
      .post(
        `https://api.echojoy.in/qc/v1/checkbalance`,
        {
          cardNumber: "7014850010000078",
          storeid: 140
        },
        {
          headers: {
            Host: "api.echojoy.in"
          }
        }
      )
      .then(response => {
        this.setState({
          amazonBalance: parseInt(response.data.data.cardbalance).toFixed(2),
          isLoading: false
        })
        .catch((error) => {
          let responseMessage = '';
          console.log(error.response.data.message)
  
          if ( error.response.data.message) {
            responseMessage = error.response.data.message;
          }
  
        
          if (responseMessage === 'TokenExpiredError') {
            localStorage.removeItem("state"); // Clear state from local storage
            window.location.href = "/login";
          } else {
            console.error("An unexpected error occurred:", responseMessage);
          }

          rej(new Error(error));
      });
    });
}

  getNonAmazonBalance() {
    axios
      .post(
        `https://api.echojoy.in/qcnamz/v1/checkbalance`,
        {
          cardNumber: "2144380020000159",
          storeid: 140
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          nonAmazonBalance: parseInt(response.data.data.cardbalance).toFixed(2),
          isLoadingNonAmazon: false
        })
        .catch((error) => {
          let responseMessage = '';
          console.log(error.response.data.message)
  
          if ( error.response.data.message) {
            responseMessage = error.response.data.message;
          }
  
        
          if (responseMessage === 'TokenExpiredError') {
            localStorage.removeItem("state"); // Clear state from local storage
            window.location.href = "/login";
          } else {
            console.error("An unexpected error occurred:", responseMessage);
          }

          rej(new Error(error));
      });
  });
}

  getFlipKartBalance() {
    axios
      .post(`https://	api2.eyantra.com/flipkart/v1/checkbalance`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${this.props.user.accessCode}`
        }
      })
      .then(response => {
        this.setState({
          flipkartBalance: parseInt(response.data.data.flipKartBalance).toFixed(
            2
          ),
          isLoadingFlipKart: false
        })
        .catch((error) => {
          let responseMessage = '';
          console.log(error.response.data.message)
  
          if ( error.response.data.message) {
            responseMessage = error.response.data.message;
          }
  
        
          if (responseMessage === 'TokenExpiredError') {
            localStorage.removeItem("state"); // Clear state from local storage
            window.location.href = "/login";
          } else {
            console.error("An unexpected error occurred:", responseMessage);
          }

          rej(new Error(error));
      });
  });
}

  totalResellerCardBalance() {
    axios
      .get(
        `https://api2.eyantra.com/ej/v2/resellers/getallresellercardbalance?page=1&size=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          totalAllResellersCardBalance:
            response.data.totalAllResellersCardBalance,
          isLoadingResellersCardBalance: false
        })
        .catch((error) => {
          let responseMessage = '';
          console.log(error.response.data.message)
  
          if ( error.response.data.message) {
            responseMessage = error.response.data.message;
          }
  
        
          if (responseMessage === 'TokenExpiredError') {
            localStorage.removeItem("state"); // Clear state from local storage
            window.location.href = "/login";
          } else {
            console.error("An unexpected error occurred:", responseMessage);
          }

          rej(new Error(error));
      });
  });
}

  componentDidMount() {
    this.getAmazonBalance();
    this.getNonAmazonBalance();
    this.getFlipKartBalance();
    this.totalResellerCardBalance();
  }

  render() {
    const {
      amazonBalance,
      nonAmazonBalance,
      isLoading,
      isLoadingNonAmazon,
      isLoadingFlipKart,
      flipkartBalance,
      isLoadingResellersCardBalance,
      totalAllResellersCardBalance
    } = this.state;
    return (
      <>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <h5 className="element-header">Balances</h5>

              {/* Amazon */}
              {isLoading === true ? (
                <div className="spinner">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <Card className="resellerCard">
                  <img
                    src="https://static.echojoy.in/logos/amazon.png"
                    class="rounded"
                  />
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span>QC-Amazon</span>
                      <span style={{ float: "right" }}>
                        &#8377; {amazonBalance}
                      </span>
                    </Card.Text>
                  </Card.Body>
                  <hr />
                  <Button
                    href="#"
                    className="addMoney-btn"
                    onClick={() => {
                      this.getAmazonBalance();
                      this.setState({
                        isLoading: true
                      });
                    }}
                  >
                    Refresh Balance
                  </Button>
                </Card>
              )}

              {/* Non Amazon */}
              {isLoadingNonAmazon === true ? (
                <div className="spinner">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <Card className="resellerCard">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span>QC-NonAmazon</span>
                      <span style={{ float: "right" }}>
                        &#8377; {nonAmazonBalance}
                      </span>
                    </Card.Text>
                  </Card.Body>
                  <hr />
                  <Button
                    href="#"
                    className="addMoney-btn"
                    onClick={() => {
                      this.getNonAmazonBalance();
                      this.setState({
                        isLoadingNonAmazon: true
                      });
                    }}
                  >
                    Refresh Balance
                  </Button>
                </Card>
              )}

              {/* Flipkart */}
              {isLoadingFlipKart === true ? (
                <div className="spinner">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <Card className="resellerCard">
                  <img
                    src="https://static.echojoy.in/logos/flipkart.png"
                    class="rounded"
                  />
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span>Flipkart</span>
                      <span style={{ float: "right" }}>
                        &#8377; {flipkartBalance}
                      </span>
                    </Card.Text>
                  </Card.Body>
                  <hr />
                  <Button
                    href="#"
                    className="addMoney-btn"
                    onClick={() => {
                      this.getFlipKartBalance();
                      this.setState({
                        isLoadingFlipKart: true
                      });
                    }}
                  >
                    Refresh Balance
                  </Button>
                </Card>
              )}

              {/* Total Balance */}
              {isLoadingResellersCardBalance === true ? (
                <div className="spinner">
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              ) : (
                <Card className="resellerCard">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span>Total Resellers Card Balance</span>
                      <span style={{ float: "right" }}>
                        &#8377;
                        {totalAllResellersCardBalance.toFixed(2)}
                      </span>
                    </Card.Text>
                  </Card.Body>
                  <hr />
                  <Button
                    href="#"
                    className="addMoney-btn"
                    onClick={() => {
                      this.totalResellerCardBalance();
                      this.setState({
                        isLoadingResellersCardBalance: true
                      });
                    }}
                  >
                    Refresh Balance
                  </Button>
                </Card>
              )}
            </Col>
          </Row>
        </Container>

        {/* <div className="balance-display">
          {isLoading ? (
            <div className="spinner">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <>
              {" "}
              <p className="data-left">
                QC-Amazon Balance:&#8377;
                {amazonBalance}
              </p>
              <p className="data-right">
                QC-NonAmazon Balance:&#8377;
                {nonAmazonBalance}
              </p>
              <p className="data-right">
                Flipkart Balance:&#8377;
                {flipkartBalance}
              </p>
            </>
          )}
        </div> */}
        {/*
        <div className="refresh-btn">
          <button
            className="button"
            onClick={() => {
              this.getAmazonBalance();
              this.getNonAmazonBalance();
              this.getFlipKartBalance();
              this.setState({
                isLoading: true
              });
            }}
          >
            Refresh Balances
          </button>
        </div>

        <div className="balance-display">
          {this.state.isLoadingResellersCardBalance ? (
            <div className="spinner">
              <Spinner animation="border" role="status"></Spinner>
            </div>
          ) : (
            <>
              {" "}
              <p className="data-left">
                totalAllResellersCardBalance:&#8377;
                {this.state.totalAllResellersCardBalance}
              </p>
            </>
          )}
        </div>

        <div className="refresh-btn">
          <button
            className="button"
            onClick={() => {
              this.totalResellerCardBalance();
              this.setState({
                isLoadingResellersCardBalance: true
              });
            }}
          >
            Refresh Total Balances
          </button>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(Balances);
