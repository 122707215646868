"use strict";
const devMode = process.env.NODE_ENV !== "production";

export const LIMITED_ACCESSS = ['9521225772'];

export function getHostUrl() {
  // if (!devMode) {
  //   return "https://api2.echojoy.co.in/ej/v2";
  // }

  return "https://api2.eyantra.com/ej/v2";
  // return "https://hoc8uc9ec1.execute-api.us-west-1.amazonaws.com/dev/v2";
  
}
